















































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  FundModifyApplyDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "FundModifyApplyList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
    formatApplyStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class NotifyList extends Vue {
  typeList: any = [];
  childList: any = [];
  statusList: any = [];
  queryForm = {
    name: "",
    type: "",
    fundCategory: "",
  };

  created() {
    this.fetchEnumType();
    this.fetchDataDictionary();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplyStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundCategory", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.childList = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundModifyApply.getAll(params);
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundModifyApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  // 查看详情
  jumpDetail(index: number, row: FundModifyApplyDto) {
    this.$router.push({
      name: "fundModifyApplyDetail",
      params: { id: row.id!.toString() },
    });
  }
}
